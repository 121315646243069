import request from "@/utils/request";
export function getWorkList(data) {
    return request({
        url: 'ewo/ewoWorkOrder/getPageList',
        method: 'post',
        data
    })
}
export function getWorkDetailList(id) {
    return request({
        url: `ewo/ewoWorkOrder/info/${id}`,
        method: 'get'
    })
}
export function getProcedureList(data) {
    return request({
        url: "/ewo/ewoProcedureWo/getEwoProcedureList",
        method: 'post',
        data
    })
}
export function upDateWorkOrder(data) {
    return request({
        url: "/ewo/ewoWorkOrder/update",
        method: 'post',
        data
    })
}
export function getProcedureDeList(id) {
    return request({
        url: `/ewo/ewoProcedureWo/info/${id}`,
        method: 'get'
    })
}
export function getEwoWorkOrderList(data) {
    return request({
        url: "/ewo/ewoWorkOrder/getEwoWorkOrderList",
        method: 'post',
        data
    })
}
export function getEwoAchieveConfigParam(data) {
    return request({
        url: "/ewo/ewoAchieveConfig/getEwoAchieveConfigParam",
        method: 'post',
        data
    })
}
export function getSysUserList(data) {
    return request({
        url: "/sysUser/getSysUserListByLabel",
        method: 'post',
        data
    })
}
export function getDefinitionModel(data) {
    return request({
        url: "ewo/definition/convertDefinitionModel",
        method: 'post',
        data
    })
}
export function upload(data) {
    return request({
        url: '/upload',
        method: 'post',
        headers :{
            'Content-Type': 'multipart/form-data'
        },
        data
    })
}
export function onModulSubmit(data){
    return request({
        url:'/ewo/definition/convertDbField',
        method:'post',
        data
    })
}
export function addWork(data){
    return request({
        url:'/ewo/ewoReportWork/add',
        method:'post',
        data
    })
}
export function addWorkOrder(data){
    return request({
        url:'/ewo/ewoWorkOrder/add',
        method:'post',
        data
    })
}
export function getDepartmentCodeList(departmentCode){
    return request({
        url:`ewo/ewoProduct/getEwoProductListByDepartmentCode/${departmentCode}`,
        method:'get'
    })
}
export function getTaskView(data){
    return request({
        url:'/ewo/ewoAppTaskApi/getTaskView',
        method:'post',
        data
    })
}
export function getTaskInfo(data){
    return request({
        url:'/ewo/ewoAppTaskApi/getTaskInfo',
        method:'post',
        data
    })
}
export function getSignature(globalUrl){
    return request({
        url:`/inf/wx/get_signature?url=${globalUrl}`
    })
}
export function getTodayReportWork(data){
    return request({
        url:"/ewo/ewoView/getTodayReportWork",
        method:'post',
        data
    })
}
export function getTodayBadReportWork(data){
    return request({
        url:'/ewo/ewoView/getTodayBadReportWork',
        method:'post',
        data
    })
}
export function getTodayWordOrder(data){
    return request({
        url:'/ewo/ewoView/getTodayWordOrder',
        method:'post',
        data
    })
}
export function getTodayBoardList(departmentCode){
    return request({
        url:`/ewo/ewoView/getTodayBoardView/${departmentCode}`
    })
}
export function getYestodayBoardList(departmentCode){
    return request({
        url: `/ewo/ewoView/getYestodayBoardView/${departmentCode}`,
        method:'post'
    })
}
export function getWeekBoardList(departmentCode){
    return request({
        url:`/ewo/ewoView/getWeekBoardView/${departmentCode}`,
        method:'post'
    })
}
export function getCode(data,id) {
    return request({
        url:`/handle/apikeys/getCode/${id}`,
        method:'get',
        data
    })
}
