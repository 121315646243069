<!--  -->
<template>
  <div class="task">
    <div class="task_headle">
      <van-popover
        v-model="dateShow"
        :actions="actions"
        @select="onSelectDate"
        class="task_headle_item line"
      >
        <template #reference>
          <div @click="dateShow = true">
            <span>{{ text }}</span>
            <span class="icon icon-qiehuan1"></span>
          </div>
        </template>
      </van-popover>
      <div class="task_headle_item" id="user" @click="sysUserShow = true">
        <span>用户</span>
        <span class="icon icon-loudoushijian"></span>
      </div>
    </div>
    <div class="work_borad">
      <div class="work_borad_item">
        <div>{{ wage }}</div>
        <div>工资总计</div>
      </div>
      <div class="work_borad_item">
        <div>{{ approve }}</div>
        <div>报工审批</div>
      </div>
      <div class="work_borad_item">
        <div>{{ goods }}</div>
        <div>良品数</div>
      </div>
      <div class="work_borad_item">
        <div>{{ defectiveProducts }}</div>
        <div>不良品数</div>
      </div>
    </div>
    <div class="all_work">
      <router-link to="/production">
        <span>查看所有任务</span><span class="icon icon-ic_arrow_r" />
      </router-link>
    </div>
    <div class="task_scroll">
      <div class="work_detailed">
        <div class="work_detailed_name">报工明细</div>
        <div class="task_content" v-if="state == true">
          <van-pull-refresh v-model="isLoading" @refresh="onLoadMore">
            <div class="task_content_item">
              <van-list
                v-model="loading"
                :finished="finished"
                error-text="请求失败，点击重新加载"
                :error.sync="error"
                finished-text="没有更多了"
                @load="onLoad"
              >
                <div class="list-item">
                  <van-cell v-for="item in taskList" :key="item.id">
                    <div class="task_content_headle">
                      <div>工单编号:{{ item.woCode }}</div>
                      <!-- <div>是否审核：{{item.}}</div> -->
                    </div>
                    <div class="task_content_num">
                      <div class="task_content_num_item">
                        <div>良品数</div>
                        <div>{{ item.goodProductCounts }}</div>
                      </div>
                      <div class="task_content_num_item">
                        <div>不良品数</div>
                        <div>{{ item.badProductCounts }}</div>
                      </div>
                      <div class="task_content_num_item">
                        <div>不良品率</div>
                        <div class="rate">{{ item.bad }}%</div>
                      </div>
                    </div>
                  </van-cell>
                </div>
              </van-list>
            </div>
          </van-pull-refresh>
        </div>
        <div class="conten_empty" id="list-content" v-if="state == false">
          <div class="conten_empty_img">
            <img src="../../common/img/kong.png" />
          </div>
          <div class="establish">
            今天还没有报工任务哦，快去
            <a @click="toWorkOrder()">
              <router-link to="">新建报工 </router-link>
            </a>
            吧!
          </div>
        </div>
      </div>
    </div>
    <div class="user_content">
      <van-popup v-model="sysUserShow" round teleport="#user">
        <van-picker
          show-toolbar
          title="筛选员工"
          :columns="userColumns"
          @confirm="onUserConfirm"
          @cancel="sysUserShow = false"
        />
      </van-popup>
    </div>
  </div>
</template>

<script>
import { getTaskView, getSysUserList } from "@/api/work";
import { mapGetters } from "vuex";
import scroll from "../../components/scroll/index";

export default {
  components: {
    scroll,
  },
  computed: {
    ...mapGetters(["userInfo", "departmentCode"]),
  },
  data() {
    //这里存放数据
    return {
      loading: false,
      isLoading: false,
      finished: false,
      error: false,
      actions: [
        { text: "今天" },
        { text: "昨天" },
        { text: "本月" },
        { text: "上月" },
        { text: "全部" },
      ],
      showPopover: false,
      userColumns: [],
      sysUserShow: false,
      dateShow: false,
      text: "今天",
      sysUserList: [],
      state: false,
      wage: 0,
      approve: 0,
      goods: 0,
      defectiveProducts: 0,
    };
  },
  //监控data中的数据变化
  watch: {},
  mounted() {
    let winHeight = document.documentElement.clientHeight; //视口大小
    document.getElementById("list-content").style.height =
      winHeight * 0.48 + "px"; //调整上拉加载框高度
  },
  //方法集合
  methods: {
    //跳转去新建工单
    toWorkOrder() {
      window.location.href = "/reportWork1";
    },
    onLoad() {
      setTimeout(() => {
        this.loading = false;
        if (this.taskList.length >= 1) {
          this.finished = true;
        }
      }, 500);
    },
    onLoadMore() {
      setTimeout(() => {
        // if (!this.enableLoadMore) {
        //   return;
        // }
        this.finished = false;
        this.isLoading = false;
        this.taskList = [];
        this.onLoad();
        this.page = this.page + 1;
        // this.getListData();
        // done();
      }, 500);
    },
    onSelectDate(v) {
      this.text = v.text;
      this.dateShow = !this.dateShow;
      this.getTaskView();
    },
    //查看报工任务
    async getTaskView(id) {
      if (id == undefined) {
        id == this["userInfo"].id;
      }
      var dateType = "";
      if (this.text == "今天") {
        dateType = 1;
      } else if (this.text == "昨天") {
        dateType = 2;
      } else if (this.text == "本月") {
        dateType = 3;
      } else if (this.text == "上月") {
        dateType = 4;
      } else if (this.text == "全部") {
        dateType = 0;
      }
      const params = {
        userId: this["userInfo"].id,
        dateType: dateType,
      };
      const res = await getTaskView(params);
      this.wage = res.wage || 0;
      this.approve = res.approve;
      this.defectiveProducts = res.defectiveProducts;
      this.goods = res.goods;
      if (res.erws == 0) {
        this.state = false;
      } else {
        this.state = true;
        res.erws.forEach((v) => {
          v.bad = (
            (v.badProductCounts /
              (v.goodProductCounts == 0 ? "1" : v.goodProductCounts)) *
            100
          ).toFixed(2);
        });
        this.taskList = res.erws;
      }
    },
    onUserConfirm(name) {
      const index = this.sysUserList.findIndex((v) => v.nickname == name);
      const userId = this.sysUserList[index].id;
      this.getTaskView(userId);
      this.sysUserShow = !this.sysUserShow;
    },
    async getSysUserList() {
      const params = {
        departmentCode: this["departmentCode"],
        labelCode: "project_person",
      };
      const res = await getSysUserList(params);
      this.sysUserList = res;
      var userColumns = [];
      res.forEach((v) => {
        userColumns.push(v.nickname);
      });
      this.userColumns = userColumns;
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getSysUserList();
    this.getTaskView();
  },
};
</script>
<style>
</style>
<style lang='less' scoped>
.task {
  width: 100vw;
  height: 100vh;

  .task_headle {
    width: 100%;
    height: 6vh;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    background-color: #fff;

    .task_headle_item {
      flex: 1;
      font-size: 30px;
      text-align: center;

      .icon {
        font-size: 40px;
      }

      .icon-loudoushijian {
        font-size: 30px;
      }
    }

    .line {
      border-right: 1px solid #999;
    }
  }

  .work_borad {
    padding: 1.5% 0px;
    width: 100%;
    height: 25vh;
    left: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .work_borad_item {
      width: 40%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      font-size: 30px;
      border-radius: 10%;
      color: #fff;
      padding: 20px 0;

      div:nth-child(2) {
        margin-top: 20px;
      }
    }

    .work_borad_item:nth-child(1) {
      background-color: #72d9c6;
      margin-left: 45px;
    }

    .work_borad_item:nth-child(2) {
      margin-left: 8%;
      background-color: #a685f7;
    }

    .work_borad_item:nth-child(4) {
      margin-top: 30px;
      margin-left: 8%;
      background-color: #ee8362;
    }

    .work_borad_item:nth-child(3) {
      margin-top: 30px;
      margin-left: 45px;
      background-color: #f7d06b;
    }
  }

  .all_work {
    margin-top: 2%;
    width: 94%;
    height: 7vh;
    margin-left: 3%;
    font-size: 31px;
    background-color: #fff;
    padding: 3%;
    border-radius: 10px;
    color: blue;
    text-align: center;
    justify-content: center;
    align-items: center;

    .icon {
      font-size: 31px;
    }

    .icon-loudoushijian {
      font-size: 31px;
    }
  }

  .task_scroll {
    width: 100%;
    height: 48vh;

    .work_detailed {
      width: 95%;
      height: 100%;

      .work_detailed_name {
        padding-top: 3%;
        padding-left: 2%;
        height: 10%;
        font-size: 36px;
        margin-bottom: 2%;
      }

      .task_content {
        width: 100%;
        height: 89%;
        overflow: auto;
        //overflow-y: scroll;

        .task_content_item {
          width: 94%;
          height: 100%;
          margin-left: 5%;
          margin-top: 30px;
          background-color: #fff;
          box-sizing: border-box;
          padding: 20px;
          border-radius: 10px;
          font-size: 30px;

          .task_content_num {
            display: flex;
            margin-top: 20px;
            align-items: center;

            .task_content_num_item {
              flex: 1;

              div:nth-child(2) {
                margin-top: 20px;
                color: #999;
              }

              .rate {
                color: red !important;
              }
            }
          }
        }
      }
    }

    .conten_empty {
      padding-top: 5%;
      width: 100%;
      height: 89%;

      .conten_empty_img {
        width: 40%;
        height: 30%;
        margin: auto;

        img {
          width: 100%;
        }
      }

      .establish {
        font-size: 30px;
        margin-top: 18%;
        text-align: center;

        a {
          color: blue;
        }
      }
    }
  }

  .user_content {
    width: 80%;
    height: 10vh;
    font-size: 30px;
  }
}
</style>
